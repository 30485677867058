import { Link } from "gatsby";
import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Li } from "../../elements/Li";
import { Text } from "../../elements/Text";
import { Ul } from "../../elements/Ul";

const PrivacyPolicyPage = () => {
  return (
    <Flex
      py={4}
      px={3}
      width="100%"
      maxWidth="600px"
      flexDirection="column"
      margin="0 auto"
    >
      <Heading textAlign="center" mb={3}>
        Privacy Policy
      </Heading>

      <Text mb={3}>
        <strong>PURPOSE OF THIS PRIVACY POLICY</strong>
      </Text>

      <Text mb={3}>
        Lean Fitness Limited respects your privacy and is committed to protecting your
        personal data. This privacy policy will inform you as to how we look after your
        personal data when you visit our website and tell you about your privacy rights
        and how the law protects you.
      </Text>
      <Text mb={3}>
        <strong>IMPORTANT INFORMATION AND WHO WE ARE</strong>
      </Text>
      <Text mb={3}>
        <strong>Purpose of this privacy policy</strong>
      </Text>
      <Text mb={3}>
        This privacy policy aims to give you information on how Lean Fitness Limited
        collects and processes your personal data through your use of this website,
        including any data you may provide through this website when you subscribe OR
        purchase a product or service OR take part in a competition.
      </Text>
      <Text mb={3}>
        This website is not intended for children, and we do not knowingly collect data
        relating to children.
      </Text>
      <Text mb={3}>
        <strong>Contact details</strong>
      </Text>
      <Text mb={3}>
        If you have any questions about this privacy policy or our privacy practices,
        please contact us in the following ways:
      </Text>
      <Text mb={3}>
        <strong>Full name of legal entity: </strong>Lean Fitness Limited
      </Text>
      <Text mb={3}>
        <strong>Email address:</strong> support@leanwithlilly.com
      </Text>
      <Text mb={3}>
        <strong>Postal address:  </strong>17-19 Foley Street, London, United Kingdom, W1W
        6DW
      </Text>
      <Text mb={3}>
        You have the right to make a complaint at any time to the Information
        Commissioner's Office (ICO), the UK regulator for data protection issues
        (www.ico.org.uk). We would, however, appreciate the chance to deal with your
        concerns before you approach the ICO so please contact us in the first instance
      </Text>
      <Text mb={3}>
        <strong>WE COLLECT THE FOLLOWING TYPES OF INFORMATION</strong>
      </Text>

      <Text>
        1. Information you provide to us directly: when you register for an account via
        email, we collect your full name, password, and email address. For the fitness
        application we also collect other information which may include your general
        health and habits (for the habit tracking session, follow-on classes and meal
        planner and physio elements of the app product) in order to provide a
        tailored-type service for each customer.
      </Text>

      <Text>Technical information we collect:</Text>

      <Ul mb={3} ml={3}>
        <Li>
          Any use of LEAN FITNESS LIMITED ’s website, software and application may result
          in the collection of technical information such as your computer’s IP address,
          mobile device identifier, meta data, operating system, browser name/version, the
          referring web page, requested page; such information is used to help us
          understand the overall usage pattern of our website and software.
        </Li>
        <Li>
          We also use third-party analytics tools to help us measure how you use LEAN
          FITNESS LIMITED. These tools collect information sent by your device or our
          Service, including how you like to use our software and website which can help
          us in improving our Service.
        </Li>
        <Li>
           LEAN FITNESS LIMITED may invite you to provide information through feedback on
          features on our website or software; we may send out e-mail inviting feedback or
          offering products and services; or we may ask you for information concerning
          support of our products or services. Your response to these communications may
          require you to submit personal information and opinions.
        </Li>
      </Ul>

      <Text mb={3}>
        <strong>HOW WE USE YOUR INFORMATION</strong>
      </Text>
      <Text mb={3}>We may use information that we receive to:</Text>

      <Ul mb={3} ml={3}>
        <Li>Help you efficiently access your information after you sign in</Li>
        <Li>
          Remember information so you will not have to re-enter it during your visit or
          the next time you visit the Service;
        </Li>
        <Li>
          Provide personalised content and information to you and others, which could
          include online ads or other forms of marketing
        </Li>
        <Li>Provide, improve, test, and monitor the effectiveness of our Service</Li>
        <Li>Develop and test new products and features</Li>
        <Li>
          Monitor metrics such as total number of visitors, traffic, and demographic
          patterns
        </Li>
        <Li>Diagnose or fix technology problems</Li>
        <Li>Automatically update the LEAN FITNESS LIMITED application on your device</Li>
      </Ul>

      <Text mb={3}>
        We have set out below, a description of all the ways we may plan to use your
        personal data, and which of the legal bases we rely on to do so. We have also
        identified what our legitimate interests are.
      </Text>
      <Text mb={3}>
        Note that we may process your personal data for more than one lawful ground
        depending on the specific purpose for which we are using your data. Please contact
        us if you need details about the specific legal ground we are relying on to
        process your personal data where more than one ground has been set out below.  
      </Text>

      <Text mb={3} underline>
        <strong>Purpose/Activity:</strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>To register you as a new customer</Li>
        <Li>To process and deliver your order including:</Li>
        <Text>(a) Manage payments, fees, and charges</Text>
        <Text>(b) Collect and recover money owed to us</Text>
        <Li>To manage our relationship with you which will include:</Li>
        <Text>(a) Notifying you about changes to our terms or privacy policy</Text>
        <Text>(b) Asking you to leave a review or take a survey</Text>
        <Li>
          To enable you to partake in a prize draw, competition or complete a survey
        </Li>
        <Li>
          To administer and protect our business and this website (including
          troubleshooting, data analysis, testing, system maintenance, support, reporting
          and hosting of data){" "}
        </Li>
        <Li>
          To deliver relevant website content and advertisements to you and measure or
          understand the effectiveness of the advertising we serve to you
        </Li>
        <Li>
          To use data analytics to improve our website, products/services, marketing,
          customer relationships and experiences
        </Li>
        <Li>
          To make suggestions and recommendations to you about goods or services that may
          be of interest to you
        </Li>
      </Ul>

      <Text mb={3} underline>
        <strong>Type of data</strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          Identity, contact, transaction, marketing and communications, identity, profile,
          usage, technical
        </Li>
      </Ul>

      <Text mb={3} underline>
        <strong>
          Lawful basis for processing including basis of legitimate interest
        </strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>Performance of a contract with you</Li>
        <Li>Necessary for our legitimate interests (to recover debts due to us)</Li>
        <Li>Necessary to comply with a legal obligation</Li>
        <Li>
          Necessary for our legitimate interests (to keep our records updated and to study
          how customers use our products/services, to study how customers use our
          products/services, to develop them and grow our business)
        </Li>
        <Li>
          Necessary for our legitimate interests (for running our business, provision of
          administration and IT services, network security, to prevent fraud and in the
          context of a business reorganisation or group restructuring exercise)
        </Li>
        <Li>
          Necessary for our legitimate interests (to study how customers use our
          products/services, to develop them, to grow our business and to inform our
          marketing strategy)
        </Li>
        <Li>
          Necessary for our legitimate interests (to develop our products/services and
          grow our business)
        </Li>
      </Ul>

      <Text mb={3}>
        <strong>PURPOSE OF USING YOUR INFORMATION</strong>
      </Text>
      <Text mb={3}>
        <strong>Marketing</strong>
      </Text>
      <Text mb={3}>
        We strive to provide you with choices regarding certain personal data uses,
        particularly around marketing and advertising.
      </Text>
      <Text mb={3}>
        <strong>Promotional offers from us</strong>
      </Text>
      <Text mb={3}>
        We may use your Identity, Contact, Technical, Usage and Profile Data to form a
        view on what we think you may want or need, or what may be of interest to you.
        This is how we decide which products, services and offers may be relevant for you
        (we call this marketing).
      </Text>
      <Text mb={3}>
        You will receive marketing communications from us if you have requested
        information from us or purchased goods or services from us and you have not opted
        out of receiving that marketing.
      </Text>
      <Text mb={3}>
        <strong>Third-party marketing</strong>
      </Text>
      <Text mb={3}>
        We will get your express opt-in consent before we share your personal data with
        any third party for marketing purposes.
      </Text>
      <Text mb={3}>
        Opting out You can ask us or third parties to stop sending you marketing messages
        at any time by following the opt-out links on any marketing message sent to you or
        by contacting us at any time.
      </Text>
      <Text mb={3}>
        Where you opt out of receiving these marketing messages, this will not apply to
        personal data provided to us as a result of a product/service purchase, warranty
        registration, product/service experience or other transactions.
      </Text>
      <Text mb={3}>
        <strong>Cookies</strong>
      </Text>
      <Text mb={3}>
        You can set your browser to refuse all or some browser cookies, or to alert you
        when websites set or access cookies. If you disable or refuse cookies, please note
        that some parts of this website may become inaccessible or not function properly.
      </Text>
      <Text mb={3}>
        <strong>Change of purpose</strong>
      </Text>
      <Text mb={3}>
        We will only use your personal data for the purposes for which we collected it,
        unless we reasonably consider that we need to use it for another reason and that
        reason is compatible with the original purpose. If you wish to get an explanation
        as to how the processing for the new purpose is compatible with the original
        purpose, please contact us.
      </Text>
      <Text mb={3}>
        If we need to use your personal data for an unrelated purpose, we will notify you
        and we will explain the legal basis which allows us to do so.
      </Text>
      <Text mb={3}>
        Please note that we may process your personal data without your knowledge or
        consent, in compliance with the above rules, where this is required or permitted
        by law.
      </Text>
      <Text mb={3}>
        <strong>SHARING OF YOUR INFORMATION</strong>
      </Text>
      <Text mb={3}>
        We will not rent or sell your information to third parties outside LEAN FITNESS
        LIMITED without your consent, except as noted in this Policy. Parties with whom we
        may share your information:
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          We may share User Content not personal information with potential business
          partners, service providers as a way of improving our service and providing
          relevant content. This will be done under confidentiality terms.
        </Li>
        <Li>
          We may remove parts of data that can identify you and share anonymized data with
          other parties. We may also combine your information with other information in a
          way that it is no longer associated with you and share that aggregated
          information.
        </Li>
        <Li>
          We may be required to disclose your information without your prior consent by
          law for the following reasons:
        </Li>
        <Ul mb={3} ml={3}>
          <Li>Comply with law or legal process</Li>
          <Li>
            Protect and defend our rights and property, or the rights and property of a
            third party
          </Li>
          <Li>
            Protect us against misuse or unauthorized use of any of  Lean Fitness Limited
            products, software, services, or other proprietary materials
          </Li>
          <Li>
            Prevent death or imminent bodily harm. prevent and address fraud and other
            illegal activity
          </Li>
        </Ul>
      </Ul>

      <Text mb={3}>
        <strong> HOW WE STORE YOUR INFORMATION</strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>Storage and Processing:</Li>
        <Ul mb={3} ml={3}>
          <Li>
            We use commercially reasonable precautions to help keep your personal
            information safe and stored securely. However, Lean Fitness Limited cannot
            ensure the security of any information you transmit to LEAN FITNESS LIMITED or
            guarantee that information on the Service may not be accessed, disclosed,
            altered, or destroyed.
          </Li>
          <Li>
            Please do your part to help us. You are responsible for maintaining the
            secrecy of your unique password and account information, and for controlling
            access to emails between you and LEAN FITNESS LIMITED, at all times.
          </Li>
          <Li>
            Your privacy settings may also be affected by changes to the social media
            services you connect to LEAN FITNESS LIMITED make to their services. We are
            not responsible for the functionality, privacy, or security measures of any
            other organization.
          </Li>
          <Li>
            Following termination or deactivation of your account, LEAN FITNESS LIMITED
            its Affiliates, or its Service Providers may retain information (including
            your profile information) and User Content for a commercially reasonable time
            for backup, archival, and/or audit purposes.
          </Li>
          <Li>
            Email our support to know about deleting your account
            support@leanwithlilly.zendesk.com.
          </Li>
        </Ul>
      </Ul>

      <Text mb={3}>
        <strong>CHILDREN'S PRIVACY</strong>
      </Text>
      <Text mb={3}>
        LEAN FITNESS LIMITED does not knowingly collect or solicit any information from
        anyone under the age of 13 or knowingly allow such persons to register for the
        Service. The Service and its content are not directed at children under the age of
        13. In the event that we learn that we have collected personal information from a
        child under age 13 without parental consent, we will delete that information as
        quickly as possible. If you believe that we might have any information from or
        about a child under 13, please contact us.
      </Text>
      <Text mb={3}>
        <strong>OTHER WEBSITES AND SERVICES</strong>
      </Text>
      <Text mb={3}>
        We are not responsible for the practices employed by any websites or services
        linked to or from our Service, including the information or content contained
        within them. Please remember that when you use a link to go from our Service to
        another website or service, our Privacy Policy does not apply to those third-party
        websites or services. Your browsing and interaction on any third-party website or
        service, including those that have a link on our website/software, or are accessed
        through our web/in-app browser are subject to that third party's own rules and
        policies. In addition, you agree that we are not responsible and do not have
        control over any third-parties that you authorize to access your User Content. If
        you are using a third-party website or service and you allow them to access your
        User Content you do so at your own risk.
      </Text>
      <Text>
        YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)
      </Text>
      <Text mb={3}>
        If you are a resident of the EEA or the UK, you have the following data protection
        rights:
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          If you wish to access, correct, update, or request deletion of your personal
          information, you can do so at any time by support@leanwithlilly.zendesk.com
        </Li>
        <Li>
          In addition, you can object to the processing of your personal information, ask
          us to restrict the processing of your personal information, or request
          portability of your personal information. Again, you can exercise these rights
          by emailing support@leanwithlilly.zendesk.com
        </Li>
        <Li>
          You have the right to opt-out of marketing communications we send you at any
          time. You can exercise this right by clicking on the "unsubscribe" or "opt-out"
          link in the marketing emails we send you. To opt-out of other forms of
          marketing, please contact us by emailing support@leanwithlilly.zendesk.com
        </Li>
        <Li>
          Similarly, if we have collected and process your personal information with your
          consent, then you can withdraw your consent at any time.  Withdrawing your
          consent will not affect the lawfulness of any processing we conducted prior to
          your withdrawal, nor will it affect the processing of your personal information
          conducted in reliance on lawful processing grounds other than consent.
        </Li>
        <Li>
          You have the right to complain to a data protection authority about our
          collection and use of your personal information. For more information, please
          contact your local data protection authority.
        </Li>
      </Ul>

      <Text mb={3}>
        We respond to all requests we receive from individuals wishing to exercise their
        data protection rights in accordance with applicable data protection laws.
      </Text>

      <Text mb={3}>
        <strong>QUESTIONS</strong>
      </Text>

      <Text mb={3}>
        If you have any questions about this Privacy Policy or the Service, please find
        the appropriate support channel in the LEAN FITNESS LIMITED settings or contact us
        at support@leanwithlilly.zendesk.com.
      </Text>

      <Text mb={3}>
        <strong>CHANGES TO OUR PRIVACY POLICY</strong>
      </Text>

      <Text>
        LEAN FITNESS LIMITED may modify or update this Privacy Policy from time to time,
        so please review it periodically. We may provide you additional forms of notice of
        modifications or updates as appropriate under the circumstances. Your continued
        use of LEAN FITNESS LIMITED or the Service after any modification to this Privacy
        Policy will constitute your acceptance of such modification. 
      </Text>
    </Flex>
  );
};

export default PrivacyPolicyPage;
